import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'py-2 px-3 text-white rounded text-xs font-semibold shadow-sm tracking-wide',
      _ctx.state === 'ONLINE'
        ? 'border-green-600 border text-green-600'
        : 'border-yellow-600 border text-yellow-600',
    ])
  }, _toDisplayString(_ctx.state), 3))
}