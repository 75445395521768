import { InjectionKey } from 'vue'

import { HttpService } from '@/util/http'

import ProductRepository from '@/data/product/repository'

export const HttpServiceKey: InjectionKey<HttpService> = Symbol('HttpService')

export const ProductRepositoryKey: InjectionKey<ProductRepository> =
  Symbol('ProductRepository')
