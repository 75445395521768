
import { defineComponent, PropType } from 'vue'

import { AppState } from '@/data/types'

export default defineComponent({
  props: {
    state: {
      type: String as PropType<keyof typeof AppState>,
      required: true,
    },
  },
})
