import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen" }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorView = _resolveComponent("ErrorView")!
  const _component_FetchingView = _resolveComponent("FetchingView")!
  const _component_ProductsView = _resolveComponent("ProductsView")!
  const _component_AppStateTag = _resolveComponent("AppStateTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state === 'ERROR')
      ? (_openBlock(), _createBlock(_component_ErrorView, {
          key: 0,
          "seconds-before-refresh": _ctx.secondsBeforeRefresh
        }, null, 8, ["seconds-before-refresh"]))
      : (_ctx.state === 'FETCHING')
        ? (_openBlock(), _createBlock(_component_FetchingView, { key: 1 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ProductsView, { products: _ctx.products }, null, 8, ["products"]),
            _createVNode(_component_AppStateTag, {
              state: _ctx.state,
              class: "fixed hidden right-3 bottom-3 2xl:block"
            }, null, 8, ["state"])
          ]))
  ]))
}