
import { defineComponent, PropType } from 'vue'
import Logo from '@/components/Logo.vue'

export default defineComponent({
  components: { Logo },
  props: {
    secondaryText: {
      type: String as PropType<string>,
      required: true,
    },
  },
})
