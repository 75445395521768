
import { defineComponent, PropType } from 'vue'
import { Product } from '@/data/product/types'
import ProductTableRow from '@/components/ProductTableRow.vue'

export default defineComponent({
  components: { ProductTableRow },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
})
