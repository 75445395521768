import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-3 py-1 mb-2 text-sm font-bold text-center text-white uppercase bg-gray-600 rounded" }
const _hoisted_2 = { class: "flex flex-col space-y-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductTableRow = _resolveComponent("ProductTableRow")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.product.name), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.variations, (variation) => {
        return (_openBlock(), _createBlock(_component_ProductTableRow, {
          key: variation.size,
          variation: variation,
          class: "w-full 2xl:w-60"
        }, null, 8, ["variation"]))
      }), 128))
    ])
  ]))
}