
import { defineComponent, PropType } from 'vue'
import { Product } from '@/data/product/types'
import ProductTable from '@/components/ProductTable.vue'

import View from '@/components/View.vue'
import Logo from '@/components/Logo.vue'

export default defineComponent({
  components: { ProductTable, View, Logo },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      default: () => [],
    },
  },
})
